@import 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap';

/* layout */
.sup-container {
  height: calc(100vh - 6.75rem);
  min-height: 50rem;
}

/* 
   Custom Scrollbar
*/
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* Grey[300] */
  background: #909090;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* Grey[400] */
  background: #767676;
}

::-webkit-resizer,
::-webkit-scrollbar-button,
::-webkit-scrollbar-corner {
  display: none;
}
