/** All size information is displayed in REM units based on the values relative to the Medium(Recommended) browser." */
body {
  background-color: #000000;
  color: #ffffff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* 전체 UI가 깨지지 않게 */
  /* TODO: 추후 디자인 필터링 시 fix */
  min-width: 110rem;
  /* Prevent bounce */
  overscroll-behavior: none;
}
textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* reset */
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
section,
article,
p,
aside,
header,
nav {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
ol,
li {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
